:root {
  --primary: #EB851E;
}

/** Boostrap 5 breakpoints

  xs  = < 576px
  sm  = ≥576px
  md  = ≥768px
  lg  =	≥992px
  xl  = ≥1200px
  xxl = ≥1400px

  Template:
  @media (min-width: 576px) { ... }
*/

@media (max-width: 575px) {

  html.loggedIn body .iubenda-tp-btn[data-tp-float][data-tp-float=bottom-left] {
    bottom: 50px !important;
  }
  html:not(.loggedIn) body .iubenda-tp-btn[data-tp-float][data-tp-float=bottom-left]  {
    margin: 0.4rem !important;
  }

  .list .row {
    border-bottom: unset !important;
  }

  .customerRow .input-group {
    max-width: 100%;
  }

  .list .rx .price {
    font-size: 5.5vw;
  }

  .changeMenu {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .ordinaContainer {
    display: flex !important;
    flex-direction: column;
    text-align: center;
    height: 115px !important;
  }

  .ordinaContainer.notAssigned {
    display: none !important;
  }

  .ordinaContainer .tableNumber {
    position: unset !important;
    width: 100% !important;
    font-size: 1.1rem !important;
    margin-bottom: 15px !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .ordinaContainer.isAdmin .buttonBox {
    justify-content: center;
  }


  .ordinaContainer .buttonBox {
    flex-direction: row;
    display: flex;
    width: 100%;
  }

  /* Sposta il menù a destra solo per xs */
  main.open .foodContainer {
    margin-left: 0 !important;
  }

  main .contentBox,
  main .container.list {
    padding: 0 5px;
  }

  .menuTypeBase {
    width: 40px;
    padding-left: 3px;
    padding-right: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .newMenuTypeName {
    padding-left: 5px;
    padding-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .menuTypeBox .startingPrice {
    padding: 0 0.5rem;
  }

  .startingPriceBox {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .menuTypeBox .currency {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 10px;
  }

  .confirmMenuTypeEdit {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  /* Cart */
  /* .cart .quantityBox {
    justify-content: center;
  } */

  .cart .rx {
    align-items: center !important;
    padding: 0;
  }
}

@media (min-width: 576px) {

  .list .rx .price {
    font-size: 1.5rem;
  }

  .changeMenu {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }

  .changeMenu:not(.sidebarChangeMenu) {
    width: 200px !important;

  }

  .ordinaContainer .buttonBox {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: end;
  }


  main.open .foodContainer {
    margin-left: 165px;
    /*150px; */
  }

  main.open .sidebar {
    width: 165px;
    /* 150px; */
  }

  main.open .hamburger {
    left: 164px;
    /* Larghezza sidebar - 1px*/
  }

  main.open .customerBox {
    width: 140px;
  }

  main.open .contentBox {
    padding-left: 5px;
    padding-right: 0;
  }

  main.open .list {
    padding-left: 0;
    padding-right: 10px;
  }
}

@media (min-width: 768px) {

  .customerRow .input-group {
    max-width: 420px !important;
  }
}

@media (min-width: 992px) {
  .ordinaContainer .buttonBox {
    justify-content: center;
  }
}

/*       ScrollBar 2        */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #E94806;
}

/* END mediaquery*/
body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

fieldset {
  margin-bottom: 1.2rem;
}

legend {
  font-size: 1.3rem;
}

hr {
  opacity: .10;
}

.info {
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.qrCodeBox {
  max-width: 500px;
}

.adminSection {
  display: block;
  position: relative;
  top: -20px;
  visibility: hidden;
}

.cartButton {
  width: 200px;
}

.bg-body-tertiary {
  background-color: unset !important;
}

.loader svg circle {
  fill: rgb(245 69 72);
  /* #FFA500;*/
}

.sidebar {
  width: 0;
  height: calc(100% - 70px);
  /* 70px di menù sottostante*/
  overflow-x: hidden;
  position: fixed;
  z-index: 1000;
  /* background-color: var(--primary) !important; */
  background: rgb(235, 133, 30);
  background: linear-gradient(270deg, rgba(235, 133, 30, 1) 0%, rgba(239, 120, 58, 1) 3%, rgba(241, 113, 74, 1) 93%, rgba(237, 66, 69, 1) 100%);
  font-weight: bold;
  letter-spacing: 1px;
}

.hamburger {
  position: fixed;
  z-index: 1000;
  left: 0;
  border-radius: unset;
  background: rgb(235, 133, 30);
  background: linear-gradient(270deg, rgba(235, 133, 30, 1) 0%, rgba(239, 120, 58, 1) 3%, rgba(241, 113, 74, 1) 95%, rgba(237, 66, 69, 1) 100%);
  border-color: rgba(235, 133, 30, 1) !important;
}

.ordinaContainer {
  bottom: 0;
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  -webkit-box-shadow: 1px -5px 19px 0px #A6A6A6;
  box-shadow: 1px -5px 19px 0px #A6A6A6;
  background: rgb(235, 133, 30);
  background: linear-gradient(0deg, rgba(235, 133, 30, 1) 0%, rgba(239, 120, 58, 1) 3%, rgba(241, 113, 74, 1) 80%, rgba(237, 66, 69, 1) 98%);
}

.ordinaContainer .tableNumber {
  font-weight: bold;
  color: white;
  position: absolute;
  left: 65px;
  font-size: 1.1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.ordinaContainer button {
  width: 50%;
}

.card-body .price {
  font-size: 1.5rem;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  margin-right: 1rem;
  font-weight: bold;
}

.card-body .order {
  height: 50px;
  font-size: 1.1rem;
  font-weight: bold;
}

.card-body .quantityButton {
  height: 50px;
  width: 100%;
}

.card-body .quantityButton .bi {
  font-size: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.card .foodImg {
  object-fit: cover;
  object-position: center;
  height: 225px;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
  display: block !important;
}

.buttonBox .quantity {
  border-radius: unset;
  width: 30%;
  text-align: center;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--bs-card-border-color);
  border-bottom: 1px solid var(--bs-card-border-color);
}

.buttonBox .change {
  padding: 0;
}

.btn.btn-warning.change {
  font-weight: bold;
}

.btn.btn-primary.change {
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.card .details {
  padding: 0;
  padding: 0.5rem 0 0.5rem 0.5rem;
}

.card .details .type {
  width: 25px;
  margin: 0 0.4rem;
}

.card .foodImg {
  margin-top: 0;
}

.card .title {
  padding: 0.6rem 0 0.6rem 0.6rem;
  margin-bottom: 0;
}

.ingredients {
  font-family: 'Arial', 'Helvetica', sans-serif;
  padding: 0;
  padding-left: 0.6rem;
  min-height: 70px;
  height: auto;
}

/* Menu */

/* List */
.list img {
  width: 100%;
  height: 85%;
  object-fit: cover;
}

.list .quantity {
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 1rem;
}

.list .rx {
  font-size: 1rem;
  font-weight: bold;
}

.list .row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.651);
}

.list .row .column {
  height: 100px;
}

/* List */

main {
  padding-bottom: 6rem;
}

button.quantity {
  width: 10%;
  height: 25px;
  position: relative;
}

button.quantity svg,
button.quantity i {
  position: absolute;
  top: 4px;
  left: 4px;
}

button.quantity i {
  top: 2px;
  left: 4px;
  font-size: 0.9rem;
}

.quantityBox {
  height: 66%;
}

.quantityBox div.quantity {
  width: 70px;
}

/* Cart */


/* Menu */
.nav-pills.menu .nav-link.active {
  border-radius: unset;
}

.nav-pills.menu .nav-link {
  cursor: pointer;
  white-space: nowrap;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/*OrderList*/
.orderListBottomMenu {
  z-index: 1000;
  left: 0;
  box-shadow: none;
}

.mealsBox {
  margin-left: 0rem;
}

/* .list .mealTitle {
  white-space: nowrap;
} */

.list img {
  width: 100%;
  height: 85%;
  object-fit: cover;
}

.list .quantity {
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 1rem;
}

.list .checkMeal {
  transform: scale(2);
  margin-right: 7px;
  cursor: pointer;
}

.newMealPrice {
  width: 50px;
  font-size: 1rem;
}

.menuIcon {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.editCategoryBox button {
  border-radius: unset;
}

.editCategoryBox input.edit {
  border-radius: unset;
}

.editCategory {
  color: rgb(255, 193, 7);
}

.editCategory:hover {
  color: rgb(170, 129, 7);
}

.deleteCategory {
  color: #dc3545;
}

.deleteCategory:hover {
  color: #a32733;
}

.editCategory.confirm {
  color: lightgreen;
}

.editCategory.confirm:hover {
  color: rgb(96, 156, 96);
}

.editMealPencil {
  position: absolute;
  font-size: 2rem;
  top: 0%;
  left: 91%;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.editMealPencil:hover {
  color: black;
}

.editNewMealBox:hover .icon {
  color: rgba(255, 255, 255, 0.849);
}

.editNewMealImage {
  position: absolute;
  font-size: 2rem;
  top: 163px;
  left: 393px;
  color: white;
  cursor: pointer;
}

.editNewMealImage:hover+.newMealImage {
  border: 2px dashed black;
}

.editNewMealImage:hover {
  color: rgba(255, 255, 255, 0.849);
}

.editNewMealImage:hover .icon {
  color: rgba(255, 255, 255, 0.849);
}

.newMealImage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7rem;
  border: 2px dashed rgba(0, 0, 0, 0.176);
  cursor: pointer;
  height: 230px;
}

.newMealImage:hover {
  border: 2px dashed black;
}

.newMealImage .icon {
  color: rgba(0, 0, 0, 0.5);
}

.newMealImage:hover .icon {
  color: black;
}

.newMealPriceBox {
  font-weight: bold;
  font-size: 1.3rem;
}

.newCategoryBox {
  border: 1px dashed white;
  margin: 0.5rem;
  cursor: pointer;
}

.newCategoryBox:hover {
  border: 1px dashed rgba(255, 255, 255, 0.849);
}

.newCategoryBox:hover .icon {
  color: rgba(255, 255, 255, 0.849);
}

.addMealBox {
  border: 2px dashed rgba(0, 0, 0, 0.176);
  cursor: pointer;
  min-height: 300px;
}

.addMealBox:hover {
  border: 2px dashed black;
}

.addMealBox .icon {
  font-size: 3rem;
  color: rgb(13, 110, 253);
}

.addMealBox:hover .icon {
  color: #0b5ed7;
}

.addMealBox .label {
  font-size: 1.5rem;
}

.list .rx {
  font-size: 1rem;
  font-weight: bold;
}

.list .row {
  border-bottom: 1px solid #F8F5F5;
}

.list .row .column {
  height: 100px;
}

.list .singleConfirm {
  width: 80%;
  height: 40px;
}

.list .singleOrderConfirm {
  margin: 1rem 0;
  width: 100%;
}


main {
  padding-bottom: 6rem;
}

button.quantity {
  width: 10%;
  height: 25px;
  position: relative;
}

button.quantity svg,
button.quantity i {
  position: absolute;
  top: 4px;
  left: 4px;
}

button.quantity i {
  top: 2px;
  left: 4px;
  font-size: 0.9rem;
}

.quantityBox {
  height: 66%;
}

.quantityBox div.quantity {
  width: 70px;
}

/* CustomerList */
.customerRow {
  border-bottom: 1px solid #CCCCCC;
  padding: 0.5rem 0;
}

.customerBox {
  width: 200px;
}

.customerBox .table {
  width: 3rem;
  margin-right: 1rem;
}

.customerBox .name {
  text-align: left;
}

.customerBox .name.table {
  font-weight: bold;
  text-align: center;
  width: 200px;
}

.customerBox .icon {
  font-size: 2rem;
}

button.updateList {
  width: 250px;
}

.confirmBox {
  width: 280px;
}

.confirmBox .icon.accepted {
  font-size: 2rem;
  color: #32cd32;
}

.confirmBox .deleteCustomer {
  padding: 1px;
  height: 2.5rem;
  width: 2.5rem;
  margin-left: auto;
}

.acceptedBox {
  font-weight: bold;
  margin: 0 auto;
}

.termsAndConditions.modal .bodyContainer{
  height: 80vh;
  overflow-y: auto;
}
.termsContainer:not(.noStyle) {
  background: white;
  height: 90vh;
  padding: 2rem;
  overflow-y: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

