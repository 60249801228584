.myNavbar {
    background: linear-gradient(0deg, rgba(235, 133, 30, 1) 0%, rgba(239, 120, 58, 1) 3%, rgba(241, 113, 74, 1) 80%, rgba(237, 66, 69, 1) 98%);
    font-weight: bold;
    letter-spacing: 1px;
}

.myNavbar .editBox {
    width: 100%;
}

.myNavbar button.navbar-toggler {
    width: 100%;
    border-color: rgba(239, 120, 58, 1);
    background: rgb(245 69 72);
}

.myNavbar .nav-item {
    text-align: center;
}

.myNavbar .nav-link {
    border-radius: 0;
}