/** Boostrap 5 breakpoints
  xs  = < 576px
  sm  = ≥576px
  md  = ≥768px
  lg  =	≥992px
  xl  = ≥1200px
  xxl = ≥1400px

  Template:
  @media (min-width: 576px) { ... }
*/
/* @media (max-width: 575px) {} */

@media (max-width: 575px) {
    .modal .row.cart .sx {
        margin-right: -3px;
    }

}
@media (max-width: 767px) {
    .modal .container.list {
        max-height: 45vh !important;
    }

}

.modal .container.list {
    max-height: 51vh;
    overflow-y: auto;
}

.modal .row.cart {
    height: 100px;
}

.cart .quantityBox div.quantity {
    width: 130px;
}

.emptyCartBox {
    text-align: center;
}

.cartBottomMenu {
    bottom: 0;
    height: 70px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    -webkit-box-shadow: 1px -5px 19px 0px #A6A6A6;
    box-shadow: 1px -5px 19px 0px #A6A6A6;
    z-index: 999;
    position: sticky;
    bottom: 0;
}

.cartOrderButton {
    width: 50%;
}

.cartBottomBox .totalBox {
    /* width: 105%; */
    position: sticky;
    bottom: 0;
}

.cartBottomBox .total {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: rgba(230, 227, 227, 0.767);
    height: 70px;
    /* border-top: 2px solid rgb(201 197 197 / 74%) */
}

.cartBottomBox .total .price {
    color: rgb(13, 110, 253);
}