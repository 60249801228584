/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);
*/

html:not(.loggedIn),
body:not(.loggedIn) {
  height: 100% !important;
}

/* */

body:not(.loggedIn) {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  background: linear-gradient(0deg, rgba(235, 133, 30, 1) 0%, rgba(239, 120, 58, 1) 3%, rgba(241, 113, 74, 1) 80%, rgba(237, 66, 69, 1) 98%)
}

body:not(.loggedIn) .googleLogin {
  border-radius: 6px !important;
}

body:not(.loggedIn) .loginLogo {
  width: 200px;
}

body:not(.loggedIn) button {
  height: 50px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

body:not(.loggedIn) .or {
  color: white;
}

body:not(.loggedIn) .form-signin {
  width: 330px;
  padding: 15px;
  margin: auto;
}

body:not(.loggedIn) .form-signin .checkbox {
  font-weight: 400;
}

body:not(.loggedIn) .form-signin .form-floating:focus-within {
  z-index: 2;
}

body:not(.loggedIn) .copyright {
  color: white;
}

body:not(.loggedIn) .form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

body:not(.loggedIn) .hero-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
body:not(.loggedIn) .consent-text {
  font-size: 0.9rem;
  color: white;
  text-align: left;
}

body:not(.loggedIn) .consent {
  margin-right: 0.6rem;
  transform: scale(1.2);
}

/* body:not(.loggedIn) .form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
} */
body:not(.loggedIn) .form-signin input.floatingPassword {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

body:not(.loggedIn) .form-signin input.floatingPasswordRegister {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

body:not(.loggedIn) .form-signin input.floatingConfirmPasswordRegister {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
}

body:not(.loggedIn) .registerForm h3 {
  color: white;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

@media (max-width: 575px) {

  body:not(.loggedIn) main {
    padding: 0 2rem !important;
  }

}

@media (max-width: 991px) {

  body:not(.loggedIn) main {
    padding-top: 6rem !important;
  }

}