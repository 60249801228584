.btn.btn-secondary {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}
.menuTypeBox .menuType {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.bottomList {
    list-style-type: none;
    /* padding-left: 14px; */
    text-align: center;
}

.bottomList a {
    text-decoration: none;
    color: white !important;
    cursor: pointer;
}

.bottomList .nav-link {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    border-radius: unset;
}

.logo h5 {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.logo {
    font-size: 2rem;
    border-bottom: 3px solid white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    display: flex;
    color: white;
    padding-bottom: 8px;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    padding-left: 10px;
    padding-right: 10px;
}

.logo img {
    width: 160px;
}