.tableListBottomBox {
    bottom: 0;
    height: 70px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    -webkit-box-shadow: 1px -5px 19px 0px #A6A6A6;
    box-shadow: 1px -5px 19px 0px #A6A6A6;
    z-index: 999;
    position: sticky;
    bottom: 0;
}