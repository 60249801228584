.menuType {
    /* cursor: pointer; */
    text-align: center;
    /* height: 75px; */
    width: 100%;
    font-size: 1.3rem;
    white-space: nowrap;
}

.changeMenu.menuType {
    width: 50px !important;
}

.menuType:hover {
    background-color: rgb(12, 94, 218) !important;
}

.menuType.selected {
    background-color: rgb(7, 69, 161) !important;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.menuTypeBox:not(.isAdmin) {
    height: 75px;
}

.menuTypeBox .removeMenuType {
    border-top-right-radius: 0;
}

.menuTypeBox .editMenuType {
    border-top-right-radius: 6px !important;
}

@media (max-width: 575px) {
    .menuTypeBox .menuType {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}